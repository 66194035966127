import { getCountries, getCountryCallingCode } from "libphonenumber-js";
import { useTransContent } from "modules/common/trans-content/transContent";
import { useEffect, useMemo, useState } from "react";
import useCountryOptions from "./useCountryOptions";

export default function useCountryCodeOptions() {
  const [countries, setCountries] = useState<{ displayLabel: string; label: string; value: string; country: string }[]>(
    []
  );
  const countryOptions = useCountryOptions();
  useEffect(() => {
    const countriesList = getCountries();
    const countriesWithCodes = countriesList.map((country) => ({
      displayLabel: countryOptions.find((option) => option.value === country)
        ? countryOptions.find((option) => option.value === country)?.label + ` (+${getCountryCallingCode(country)})`
        : getCountryCallingCode(country),
      label: getCountryCallingCode(country),
      value: getCountryCallingCode(country),
      country: country,
    }));
    setCountries(countriesWithCodes);
  }, []);
  return countries;
}
